import * as React from "react"

const NotFoundPage = () => (
  <>
    <h1>You are here!</h1>
    <h2>But here doesn't exist.</h2>
  </>
)

export default NotFoundPage
